// template-folder-name -> Login.tsx
import axios from "axios";
import { removeStringQuote } from "../Helper";
import "./component/style/Login.css";

export const useLogin = () => {
  const url = 'https://api-ihr.agileexlab.net/ihr-luck/v4/api/login';
  // const url = 'http://localhost:8000/api/login';
  const verifyLogin = async (username: string, password: string) => {
    await axios
      .post(url, { inputId: username, inputPassword: password }, {headers: { Accept: "application/json", 'Content-Type': "application/json" }})
      .then((res) => {
        if (res.data.payload !== "") {
          localStorage.setItem("loginToken", removeStringQuote(JSON.stringify(res.data.payload)));
          window.location.href = '/user';
        } else {
          window.location.href = '/login';
        }
      })
      .catch((err) => {
        localStorage.removeItem("username");  
        localStorage.removeItem("loginToken");  
        if (err.response?.status === 401) {
          window.alert("Unauthorized: Invalid Password.");
        } else if (err.response?.status === 500) {
          window.alert("Server Error, invalid username.");
        } else {
          window.alert("Unexpected error. Please Refresh UI and backend.");
        }
        console.log(err);
      })
      .finally(() => {
      });
  }
  return { verifyLogin }
}