import React from 'react';
import './Streaming.css';
const Streaming: React.FC = () => {
  const YoutubeLink = "https://www.youtube.com/embed/VdxFlIRZQHQ?si=QN5u6W2Y-Gk4bzgA";
  return (
    <div className="streaming-container">
      <iframe
        className="streaming-iframe"
        src={YoutubeLink}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>

  );
};

export default Streaming;
