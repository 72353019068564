// AccountHealthCheckTable.tsx  
import React, { useEffect, useState, useCallback } from 'react';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';

import { TransactionTable } from '../TableTransaction/TransactionTable';
import { TransactionDialog } from '../TableTransaction/TransactionDialog';
import { RaceDateSelect } from '../TableTransaction/RaceDateSelect';

interface Info {
  currentAccountBalance: number;
  bettingCommand: { [key: string]: any };
  accountTransaction: { [key: string]: any };
  betTime: string;
  bettingUnit: string;
  updatedTime: string;
}

interface UserAccountInfo {
  username: string;
  displayName: string;
  bettingChoice: string;
  bettingUnit: number;
  currentBalance: number;
  betbotStatus: string;
  code: string;
}

const AccountHealthCheckTable: React.FC = () => {
  const [userAccounts, setUserAccounts] = useState<UserAccountInfo[]>([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  // const [displayUsername, setDisplayUsername] = useState<string | null>(null);
  const [selectedUserTransactions, setSelectedUserTransactions] = useState<Info | null>(null);
  const [selectedUser, setSelectedUser] = useState<string | null>(null);
  // const [info, setInfo] = useState<Info | null>(null);
  const [raceDates, setRaceDates] = useState([]);
  const [selectedRaceDate, setRaceDate] = useState('latest');
  const [open, setOpen] = useState(false);
  const [selectedKey, setSelectedKey] = useState(null);
  const [queryBeforeBetBalance, setQueryBeforeBetBalance] = useState(null)
  const [queryAfterBetBalance, setQueryAfterBetBalance] = useState(null)
  const [queryAfterDividendBalance, setQueryAfterDividendBalance] = useState(null)
  const [queryResult, setQueryResult] = useState(null);
  const [queryMessage, setQueryMessage] = useState(null);

  // Calculate the number of rows needed for the grid  
  const MAX_COLUMNS = 5; // Maximum number of columns  
  const numRows = Math.ceil(userAccounts.length / MAX_COLUMNS);

  // Create an array of rows, each containing a slice of user accounts  
  const rows = Array.from({ length: numRows }, (_, rowIndex) => {
    const start = rowIndex * MAX_COLUMNS;
    const end = start + MAX_COLUMNS;
    return userAccounts.slice(start, end);
  });

  const fetchData = useCallback(async () => {
    setSnackbarOpen(true); // Open the Snackbar when refresh starts  
    const response = await fetch('https://api-ihr.agileexlab.net/ihr-luck/v4/api/account');
    // const response = await fetch('http://localhost:8000/api/account');
    const data = await response.json();
    let usersData: UserAccountInfo[] = Object.keys(data.payload.users).map((username) => ({
      username,
      displayName: data.payload.users[username].display_name,
      bettingChoice: data.payload.users[username].betting_choice,
      bettingUnit: data.payload.users[username].betting_unit,
      currentBalance: data.payload.users[username].current_balance,
      betbotStatus: data.payload.users[username].betbot_status,
      code: data.payload.users[username].code,
    }));
    // Sorting usersData by code  
    usersData = usersData.sort((a, b) => {  
      const codeANum = parseInt(a.code.substring(1)); // Remove the '#' and parse to integer  
      const codeBNum = parseInt(b.code.substring(1)); // Remove the '#' and parse to integer  
      return codeANum - codeBNum; // Sort in ascending order  
    });  
    setUserAccounts(usersData);
    setTimeout(() => setSnackbarOpen(false), 3000); // Close the Snackbar after 3 seconds  
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    const fetchTransaction = () => {
      if (selectedUser === "" || !selectedUser) {
        return;
      }
      fetch('https://api-ihr.agileexlab.net/ihr-luck/v4/api/transaction', {
      // fetch('http://localhost:8000/api/transaction', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: selectedUser,
          race_date: selectedRaceDate, // add this line
        }),
      })
        .then(response => response.json())
        .then(data => {
          setSelectedUserTransactions({
            currentAccountBalance: data.payload.current_account_balance,
            bettingCommand: data.payload.betting_command,
            accountTransaction: data.payload.account_transaction,
            betTime: data.payload.bet_time,
            bettingUnit: data.payload.betting_unit,
            updatedTime: new Date().toLocaleTimeString(),
          });
        })
        .catch(error => console.error('Error:', error));
    };

    // Fetch data immediately  
    fetchTransaction();

    // setDialogOpen(true);
  }, [selectedRaceDate, selectedUser]);

  const handleKeyClick = async (key: React.SetStateAction<null>) => {
    setSelectedKey(key);
    // Use the `key` parameter directly for the API call  
    const response = await fetch('https://api-ihr.agileexlab.net/ihr-luck/v4/api/race-detail', {
      // const response = await fetch('http://localhost:8000/api/race-detail', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },

      body: JSON.stringify({
        username: selectedUser,
        // race_date: "latest",
        race_date: selectedRaceDate,
        race_num: parseInt(key ? key.toString() : "1") // Ensure key is not null, then parse it  
      }),
    });

    try {
      const data = await response.json();
      setQueryBeforeBetBalance(data.payload.user_balance.before_balance);
      setQueryAfterBetBalance(data.payload.user_balance.after_balance);
      setQueryAfterDividendBalance(data.payload.user_balance.after_dividend_balance ? data.payload.user_balance.after_dividend_balance : 0.0);
      setQueryResult(data.payload.result.result.join(' '));
      setQueryMessage(data.payload.message.bet_message);
      setOpen(true); // Open the dialog after the state has been updated  
    } catch (error) {
      console.error('Error:', error);
    }
  };
  const handleUserIdClick = async (username: string) => {
    // Fetch the transactions of the clicked user
    fetch('https://api-ihr.agileexlab.net/ihr-luck/v4/api/race-date', {
      // fetch('http://localhost:8000/api/race-date', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username: username,
      }),
    })
      .then(response => response.json())
      .then(data => {
        setRaceDates(data.payload.race_date_list);
        setRaceDate(data.payload.race_date_list[0]);
        // setDisplayUsername(username);
        setSelectedUser(username);
      })
      .catch(error => console.error('Error:', error));
    setDialogOpen(true);
  };

  let accountTransactions = [];
  if (selectedUserTransactions && selectedUserTransactions.accountTransaction) {
    accountTransactions = Object.entries(selectedUserTransactions.accountTransaction)
      .map(([key, value]) => ({ key, ...value }))
      .sort((a, b) => a.key - b.key);
  }

  const getStatusColor = (status: any) => {
    switch (status) {
      case 'Online':
        return 'green';
      case 'Offline':
        return 'red';
      default:
        return 'grey';
    }
  };
  const totalAutoBetAmount = accountTransactions.reduce((total, transaction) => total + parseFloat(transaction.bet_amount || 0), 0);  
  const totalCustomBetAmount = accountTransactions.reduce((total, transaction) => total + parseFloat(transaction.custom_bet_amount || 0), 0);  
  const totalBetAmount = totalAutoBetAmount + totalCustomBetAmount;
  const totalProfit = accountTransactions.reduce((total, transaction) => {  
    if (parseFloat(transaction.after_balance) === 0.0 || parseFloat(transaction.after_balance) === -1.0) {
      // console.log("Error in After Bet Balance");
      return total;
    }
    if (parseFloat(transaction.after_dividend_balance) === 0.0 || parseFloat(transaction.after_dividend_balance) === -1.0) {
      // console.log("Error in After Dividend Balance");
      return total;
    }
    if (transaction.after_dividend_balance > transaction.after_balance) {  
      return total + (transaction.after_dividend_balance - transaction.after_balance);  
    }  
    return total;  
  }, 0);
  let profitRatio = 0;    
  if (totalBetAmount !== 0) {    
    profitRatio = ((totalProfit-totalBetAmount) / totalBetAmount) * 100;  
  }
  

  return (
    <>
      <TransactionDialog open={open} setOpen={setOpen} selectedKey={selectedKey} queryMessage={queryMessage} queryResult={queryResult} queryBeforeBetBalance={queryBeforeBetBalance} queryAfterBetBalance={queryAfterBetBalance} queryAfterDividendBalance={queryAfterDividendBalance} />
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>Transactions of {selectedUser}</DialogTitle>
        <DialogContent>
          {/* <RaceDateSelect selectedRaceDate={selectedRaceDate} raceDates={raceDates} setRaceDate={setRaceDate} /> */}
          <>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <RaceDateSelect selectedRaceDate={selectedRaceDate} raceDates={raceDates} setRaceDate={setRaceDate} />
                <p>Race Day Investment: ${totalBetAmount.toFixed(0)} | Day end Dividend: ${totalProfit.toFixed(0)} | Race Day Profit/Loss: {profitRatio.toFixed(2)}%</p>
              </div>
            </>
          <TransactionTable accountTransactions={accountTransactions} handleKeyClick={handleKeyClick} />
        </DialogContent>
      </Dialog>

      <Button
        variant="contained"
        color="primary"
        onClick={fetchData}
        sx={{ margin: '10px' }}
      >
        Refresh Data
      </Button>

      <TableContainer component={Paper} sx={{ maxHeight: 350, overflow: 'auto' }}>
        <Table sx={{ minWidth: 250 }} aria-label="account health check table">
          <TableBody>
            {rows.map((row, rowIndex) => (
              <TableRow key={rowIndex}>
                {row.map((userAccount) => (
                  <TableCell key={userAccount.username} align="center">
                    {/* Display user information here */}
                    <div>
                      <Button
                        variant="contained"
                        size="small"
                        style={{
                          color: '#fff', width: "90px",
                          backgroundColor: getStatusColor(userAccount.betbotStatus)
                        }}
                        sx={{ marginRight: 1 }}
                        onClick={() => {
                          handleUserIdClick(userAccount.username);
                        }}
                      >
                        {userAccount.displayName}
                      </Button>
                      <div>{userAccount.bettingChoice === "Auto-bet" ? "AUTO" : "NO"} | {userAccount.bettingUnit} | ${userAccount.currentBalance ? userAccount.currentBalance : "/"}</div>
                    </div>
                  </TableCell>
                ))}
                {/* Fill in the remaining cells if the last row is not full */}
                {row.length < MAX_COLUMNS &&
                  Array.from({ length: MAX_COLUMNS - row.length }, (_, index) => (
                    <TableCell key={`empty-${rowIndex}-${index}`} />
                  ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Snackbar
        open={snackbarOpen}
        message="Data refreshed!"
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
      />
    </>
  );
};

export default AccountHealthCheckTable;  
