import React from "react";  
import Dialog from '@mui/material/Dialog';  
import DialogTitle from '@mui/material/DialogTitle';  
import DialogContent from '@mui/material/DialogContent';  
  
interface DialogProps {  
  open: boolean;  
  setOpen: (value: boolean) => void;  
  selectedKey: any;  
  queryMessage: any;  
  queryResult: any;  
  queryBeforeBetBalance: any;  
  queryAfterBetBalance: any;  
  queryAfterDividendBalance: any;  
}  
  
export const TransactionDialog: React.FC<DialogProps> = ({ open, setOpen, selectedKey, queryMessage, queryResult, queryBeforeBetBalance, queryAfterBetBalance, queryAfterDividendBalance }) => {
  const betAmount = (queryBeforeBetBalance && queryAfterBetBalance) ? queryBeforeBetBalance - queryAfterBetBalance : 0.0;
  const dividend = (queryAfterDividendBalance && queryAfterBetBalance) ? queryAfterDividendBalance - queryAfterBetBalance : 0.0;
  return (  
    <Dialog open={open} onClose={() => setOpen(false)}>  
      <DialogTitle>Transaction Details</DialogTitle>  
      <DialogContent>  
        {/* <p>Race Number: {selectedKey}</p>  
        <p>Result: {queryResult}</p>  
        <p>Before Bet Balance: {queryBeforeBetBalance ? queryBeforeBetBalance.toFixed(1) : 0.0}</p>  
        <p>(Bet Amount: {betAmount ? betAmount.toFixed(1) : 0.0})</p>  
        <p>After Bet Balance: {queryAfterBetBalance ? queryAfterBetBalance.toFixed(1) : 0.0}</p>  
        <p>(Dividend: {dividend ? dividend.toFixed(1) : 0.0})</p>  
        <p>After Dividend Balance: {queryAfterDividendBalance ? queryAfterDividendBalance.toFixed(1) : 0.0}</p>   */}
        <p>Race Number: {selectedKey}</p>  
        {/* <p>Message: {queryMessage}</p>   */}
        <p>Result: {queryResult}</p>  
        <p>Before Bet Balance: {queryBeforeBetBalance ? queryBeforeBetBalance : 0.0}</p>  
        <p>(Bet Amount: {betAmount ? betAmount : 0.0})</p>  
        <p>(Dividend: {betAmount ? betAmount : 0.0})</p>  
        <p>After Bet Balance: {queryAfterBetBalance ? queryAfterBetBalance : 0.0}</p>  
        <p>(Dividend: {dividend ? dividend : 0.0})</p>  
        <p>After Dividend Balance: {queryAfterDividendBalance ? queryAfterDividendBalance : 0.0}</p>  
      </DialogContent>  
    </Dialog>  
  );  
};  
