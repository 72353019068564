// IMPORTS
import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import CardContent from "@mui/material/CardContent";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import "./style.css"
import { RaceDateSelect } from '../TableTransaction/RaceDateSelect';
import { TransactionTable } from '../TableTransaction/TransactionTable';
import { SettingsForm } from './component/SettingsForm';
import { ControlForm } from './component/ControlForm';
import { TransactionDialog } from '../TableTransaction/TransactionDialog';
import BetCommandInput from '../TableBetting/BettingCard';
import AccountHealthCheckTable from '../TableAccount/AccountTable'
import Snackbar from '@mui/material/Snackbar';
interface Info {
  currentAccountBalance: number;
  // bettingCommand: { [key: string]: any };
  accountTransaction: { [key: string]: any };
  currentCommand: string,
  // landscape: { [key: string]: any };
  updatedTime: string;
}

//APP
export default function SettingsCard(props: any) {
  //TAB STATES
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [value, setValue] = React.useState("one");
  const [info, setInfo] = useState<Info | null>(null);
  const [selectedRaceDate, setRaceDate] = useState('latest');
  const [raceDates, setRaceDates] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedKey, setSelectedKey] = useState(null);
  const [queryResult, setQueryResult] = useState(null);
  const [queryMessage, setQueryMessage] = useState(null);
  // const [queryAfterBalance, setQueryAfterBalance] = useState(null);
  // const [queryBeforeBalance, setQueryBeforeBalance] = useState(null);

  const [queryBeforeBetBalance, setQueryBeforeBetBalance] = useState(null)
  const [queryAfterBetBalance, setQueryAfterBetBalance] = useState(null)
  const [queryAfterDividendBalance, setQueryAfterDividendBalance] = useState(null)

  useEffect(() => {
    const fetchData = () => {
      const username = localStorage.getItem('username');
      fetch('https://api-ihr.agileexlab.net/ihr-luck/v4/api/transaction', {
      // fetch('http://localhost:8000/api/transaction', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: username,
          race_date: selectedRaceDate, // add this line
        }),
      })
        .then(response => response.json())
        .then(data => {
          setInfo({
            currentAccountBalance: data.payload.current_account_balance,
            // bettingCommand: data.payload.betting_command,
            currentCommand: data.payload.current_command,
            accountTransaction: data.payload.account_transaction,
            // landscape: data.payload.landscape,
            updatedTime: new Date().toLocaleTimeString(),
          });
        })
        .catch(error => console.error('Error:', error));
    };
    // Fetch data immediately  
    fetchData();

    // Fetch data every 10 seconds  
    const intervalId = setInterval(fetchData, 10000);

    // Cleanup on unmount  
    return () => clearInterval(intervalId);
  }, [selectedRaceDate]);

  useEffect(() => {
    const fetchData = () => {
      const username = localStorage.getItem('username');
      fetch('https://api-ihr.agileexlab.net/ihr-luck/v4/api/race-date', {
        // fetch('http://localhost:8000/api/race-date', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: username,
          race_date: selectedRaceDate, // add this line ,
        }),
      })
        .then(response => response.json())
        .then(data => {
          setRaceDates(data.payload.race_date_list);
          if (!selectedRaceDate) {
            setRaceDate(data.payload.race_date_list[0]);
          }
        })
        .catch(error => console.error('Error:', error));
    }

    // Fetch data immediately  
    fetchData();
    // Fetch data every 10 seconds  
    const intervalId = setInterval(fetchData, 10000);

    // Cleanup on unmount  
    return () => clearInterval(intervalId);
  }, []);

  let accountTransactions = [];
  if (info && info.accountTransaction) {
    accountTransactions = Object.entries(info.accountTransaction)
      .map(([key, value]) => ({ key, ...value }))
      .sort((a, b) => a.key - b.key);
    // console.log(info)
  }

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  //BUTTON STATES
  const [edit, update] = useState({
    required: true,
    disabled: true,
    isEdit: true
  });
  const handleKeyClick = async (key: React.SetStateAction<null>) => {
    setSelectedKey(key);
    const username = localStorage.getItem('username');

    // Use the `key` parameter directly for the API call  
    const response = await fetch('https://api-ihr.agileexlab.net/ihr-luck/v4/api/race-detail', {
      // const response = await fetch('http://localhost:8000/api/race-detail', {  
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },

      body: JSON.stringify({
        username: username,
        race_date: selectedRaceDate,
        race_num: parseInt(key ? key.toString() : "1") // Ensure key is not null, then parse it  
      }),
    });

    try {
      const data = await response.json();
      setQueryBeforeBetBalance(data.payload.user_balance.before_balance);
      setQueryAfterBetBalance(data.payload.user_balance.after_balance);
      setQueryAfterDividendBalance(data.payload.user_balance.after_dividend_balance ? data.payload.user_balance.after_dividend_balance : 0.0);
      setQueryResult(data.payload.result.result.join(' '));
      setQueryMessage(data.payload.message.bet_message);
      setOpen(true); // Open the dialog after the state has been updated  
    } catch (error) {
      console.error('Error:', error);
    }
  };
  const handleCustomBetClick = async (selectedRows: any, rows: any) => {
    setSnackbarOpen(true); // Open the Snackbar when refresh starts  
    const username = localStorage.getItem('username');
    const selectedCommands = rows
      .filter((_: any, index: string | number) => selectedRows[index]) // Filter only selected rows  
      .map((row: { numbers: any[]; label: string; amount: any; }) => {
        // Create a new array excluding the first element and join non-empty numbers with commas  
        const numbersWithoutFirst = row.numbers.slice(1).filter(Boolean).join(',');
        console.log(row.label.toLowerCase())
        if (row.label.toLowerCase() === 'win') {
          // If the label is 'WIN', create a 'win' command without the '>'  
          return `win:${row.numbers[0]}/$${row.amount}`;
        } else if (row.label.toLowerCase() === 'pla') {
          return `pla:${row.numbers[0]}/$${row.amount}`;
        }  else {
          // For other labels, create a single command excluding the first number  
          return `${row.label.toLowerCase()}:${row.numbers[0]}>${numbersWithoutFirst}/$${row.amount}`;
        }
      });
    try {
      const response = await fetch('https://api-ihr.agileexlab.net/ihr-luck/v4/api/betbot/custom-betting', {
      // const response = await fetch('http://localhost:8000/api/betbot/custom-betting', {  
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },

        body: JSON.stringify({
          username: username,
          command: selectedCommands
        }),
      });
      // const response = await axios.post('http://localhost:8000/api/betbot/custom-betting', requestBody);
      // console.log(response.data);
    } catch (error) {
      console.error('Error sending API request:', error);
      // Handle error  
    }

    setTimeout(() => setSnackbarOpen(false), 3000); // Close the Snackbar after 3 seconds  
  };

  const handleCustomBetClickCommandLine = async (command: any) => {
    setSnackbarOpen(true); // Open the Snackbar when refresh starts  
    const username = localStorage.getItem('username');

    try {
      const response = await fetch('https://api-ihr.agileexlab.net/ihr-luck/v4/api/betbot/custom-betting', {
      // const response = await fetch('http://localhost:8000/api/betbot/custom-betting', {  
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },

        body: JSON.stringify({
          username: username,
          command: [command]
        }),
      });
      // const response = await axios.post('http://localhost:8000/api/betbot/custom-betting', requestBody);
      // console.log(response.data);
    } catch (error) {
      console.error('Error sending API request:', error);
      // Handle error  
    }

    setTimeout(() => setSnackbarOpen(false), 3000); // Close the Snackbar after 3 seconds  
  };
  const updateBettingUnit = (bettingUnit: string) => {
    const username = localStorage.getItem('username');
    // Send a request here. For example:  
    fetch('https://api-ihr.agileexlab.net/ihr-luck/v4/api/update-betting-unit', {
      // fetch('http://localhost:8000/api/update-betting-unit', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username: username,
        betting_unit: bettingUnit, // 
      }),
    })
      .then(response => response.json())
      .then(data => console.log(data))
      .catch(error => console.error('Error:', error));
  };
  const updateComment = async (comment: string) => {
    const username = localStorage.getItem('username');
    // Send a request here. For example:  
    fetch('https://api-ihr.agileexlab.net/ihr-luck/v4/api/admin/update-comment', {
      // fetch('http://localhost:8000/api/admin/update-comment', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username: username,
        comment: comment,
      }),
    })
      .then(response => response.json())
      .then(data => console.log(data))
      .catch(error => console.error('Error:', error));
  };
  const resetComment = async () => {
    const username = localStorage.getItem('username');
    // Send a request here. For example:  
    fetch('https://api-ihr.agileexlab.net/ihr-luck/v4/api/admin/reset-comment', {
      // fetch('http://localhost:8000/api/admin/reset-comment', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username: username,
      }),
    })
      .then(response => response.json())
      .then(data => console.log(data))
      .catch(error => console.error('Error:', error));
  };
  const totalAutoBetAmount = accountTransactions.reduce((total, transaction) => total + parseFloat(transaction.bet_amount || 0), 0);  
  const totalCustomBetAmount = accountTransactions.reduce((total, transaction) => total + parseFloat(transaction.custom_bet_amount || 0), 0);  
  const totalBetAmount = totalAutoBetAmount + totalCustomBetAmount;
  const totalProfit = accountTransactions.reduce((total, transaction) => {
    if (parseFloat(transaction.after_balance) === 0.0 || parseFloat(transaction.after_balance) === -1.0) {
      return total;
    }
    if (parseFloat(transaction.after_dividend_balance) === 0.0 || parseFloat(transaction.after_dividend_balance) === -1.0) {
      return total;
    }
    if (transaction.after_dividend_balance > transaction.after_balance) {
      return total + (transaction.after_dividend_balance - transaction.after_balance);
    }
    return total;
  }, 0);

  let profitRatio = 0;
  if (totalBetAmount !== 0) {
    profitRatio = ((totalProfit - totalBetAmount) / totalBetAmount) * 100;
  }


  //RETURN
  return (
    <>
      <Card variant="outlined" sx={{ height: "100%", width: "100%" }}>
        <TransactionDialog open={open} setOpen={setOpen} selectedKey={selectedKey} queryMessage={queryMessage} queryResult={queryResult} queryBeforeBetBalance={queryBeforeBetBalance} queryAfterBetBalance={queryAfterBetBalance} queryAfterDividendBalance={queryAfterDividendBalance} />

        {/* TABS */}
        <br></br>
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="secondary"
          indicatorColor="secondary"
        >
          <Tab value="one" label="Race" />
          {
            (props.role === "admin") &&
            <Tab value="two" label="Account" />
          }
          <Tab value="three" label="Settings" />
          {/* {
            (props.role === "admin") &&
            <Tab value="four" label="Control" />
          } */}
          {
            (props.role === "admin") &&
            <Tab value="five" label="Betting" />
          }
        </Tabs>
        <Divider></Divider>

        {/* MAIN CONTENT CONTAINER */}
        <form>
          <CardContent
            sx={{
              p: 3,
              maxHeight: { md: "40vh" },
              textAlign: { xs: "center", md: "start" }
            }}
          >
            {value === "one" && (
              <>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <RaceDateSelect selectedRaceDate={selectedRaceDate} raceDates={raceDates} setRaceDate={setRaceDate} />
                  {/* <div className="profit-text"> */}
                  <p className="profit-text">Race Day Investment: ${totalBetAmount.toFixed(0)}</p>
                  <p className="profit-text"> Day end Dividend: ${totalProfit.toFixed(0)}</p>
                  <p className="profit-text">Race Day Profit/Loss: {profitRatio.toFixed(2)}%</p>

                  {/* </div> */}
                </div>
              </>
            )}
            {value === "one" && info && info.currentAccountBalance && (
              <TransactionTable accountTransactions={accountTransactions} handleKeyClick={handleKeyClick} />
            )}

            {value === "two" && (props.role === "admin") && (
              <AccountHealthCheckTable />
            )}

            {value === "three" && (
              <SettingsForm edit={edit} update={update} expose={updateBettingUnit} />
            )}
            {/* {value === "four" && (
              <ControlForm edit={edit} update={update} expose={updateComment} reset={resetComment} />
            )} */}
            {value === "five" && (
              // <BetCommandInput betCommandLabel="win:3/$10" handleCustomBetClick={handleCustomBetClick} />
              <BetCommandInput betCommandLabel={info ? info.currentCommand : ""} handleCustomBetClick={handleCustomBetClick} handleCustomBetClickCommandLine={handleCustomBetClickCommandLine} />
            )}

          </CardContent>

        </form>
      </Card>
      <Snackbar
        open={snackbarOpen}
        message="Placed manual bet"
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
      />
    </>
  );
}


