import React, { useState } from "react";  
import FormControl from "@mui/material/FormControl";  
import Grid from "@mui/material/Grid";  
import Button from "@mui/material/Button";  
import CustomInput from "../../TableSetting/CustomInput";  
import Snackbar from '@mui/material/Snackbar';
  
interface SettingsFormProps {  
  edit: any;  
  update: any;  
  expose: any;  
}  
  
export const SettingsForm: React.FC<SettingsFormProps> = ({ edit, update, expose }) => {  
  const [bettingUnit, setBettingUnit] = useState(""); // Add this line  
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  
  const handleBettingUnitChange = (event: React.ChangeEvent<HTMLInputElement>) => {  
    setBettingUnit(event.target.value); // Add this line  
    // setSnackbarOpen(true); // Open the Snackbar when refresh starts  
    // setTimeout(() => setSnackbarOpen(false), 3000); // Close the Snackbar after 3 seconds  
  };  
  
  return (  
    <form>  
      <FormControl fullWidth>  
        <Grid  
          container  
          direction={{ xs: "column", md: "row" }}  
          columnSpacing={5}  
          rowSpacing={3}  
        >  
          <Grid component="form" item xs={6}>  
            <CustomInput  
              id="unit"  
              name="unit"  
              value={bettingUnit}  
              onChange={handleBettingUnitChange}  
              title="Betting Unit"  
              dis={edit.disabled}  
              req={edit.required}  
            />  
          </Grid>  
          <Grid  
            container  
            justifyContent={{ xs: "center", md: "flex-end" }}  
            item  
            xs={6}  
          >  
            <Button  
              sx={{ p: "1rem 2rem", my: 2, height: "3rem" }}  
              component="button"  
              size="large"  
              variant="contained"  
              color="secondary"  
              onClick={() => { expose(bettingUnit); setSnackbarOpen(true); setTimeout(() => setSnackbarOpen(false), 3000); } } // Change this line  
            >  
              {edit.isEdit === false ? "UPDATE" : "EDIT"}  
            </Button>  
          </Grid>  
        </Grid>  
      </FormControl>  
      <Snackbar
        open={snackbarOpen}
        message="Edited Betting Unit"
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
      />
    </form>  
  );  
};  
