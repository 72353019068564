import React from "react";
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Paper from '@mui/material/Paper';
import Button from "@mui/material/Button";
import "./style.css"
interface TransactionTableProps {
  accountTransactions: any[];
  handleKeyClick: (key: React.SetStateAction<null>) => void;
}

export const TransactionTable: React.FC<TransactionTableProps> = ({ accountTransactions, handleKeyClick }) => {
  const showTransaction = (transaction: any) => {
    if (!transaction.bet_time) {
      transaction.bet_time = "/"
    }
    if (!transaction.betting_unit) {
      transaction.betting_unit = "-"
    }
    if (transaction.before_balance && transaction.before_balance !== -1 && transaction.after_balance && transaction.after_balance !== -1) {
      if (transaction.before_balance !== transaction.after_balance) {
        return `${transaction.bet_time} - Betted (${transaction.betting_unit}U)`
      } else {
        return `${transaction.bet_time} - Not Betted`
      }
    } else {
      return `${transaction.bet_time} - Not Betted`
    }
  }
  const showCustomTransaction = (transaction: any) => {
    if (transaction.custom_bet_amount && transaction.custom_bet_time) {
      return `${transaction.custom_bet_time} - Betted $${transaction.custom_bet_amount}`
    } else {
      return "Not Betted"
    }
  }

  const calculateBetAmount = (transaction: any) => {
    let transaction_bet_amount = 0
    if (transaction.bet_amount && transaction.bet_amount !== -1) {
      transaction_bet_amount += transaction.bet_amount
    }
    if (transaction.custom_bet_amount && transaction.custom_bet_amount !== -1) {
      transaction_bet_amount += transaction.custom_bet_amount
    }
    return transaction_bet_amount
  }
  const calculateDividend = (transaction: any) => {
    let transaction_dividend = 0
    if (transaction.after_dividend_balance && transaction.after_dividend_balance !== -1 && transaction.after_balance && transaction.after_balance !== -1) {
      transaction_dividend = transaction.after_dividend_balance - transaction.after_balance
    }
    return transaction_dividend
  }
  return (
    <TableContainer component={Paper} sx={{ maxHeight: 350, overflow: 'auto' }}>
      <Table sx={{ minWidth: 250 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Race Number</TableCell>
            <TableCell align="right">Bet Amount</TableCell>
            <TableCell align="right">Dividend</TableCell>
            <TableCell align="right">Betting Command</TableCell>
            <TableCell align="right">Custom Command</TableCell>
            <TableCell align="right">Updated Balance</TableCell>
            {/* <TableCell align="right">Landscape</TableCell> */}
            <TableCell align="right">Banker</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {accountTransactions.length > 0 ? (
            [...accountTransactions].reverse().map((transaction) => (
              <TableRow key={transaction.key}>
                <TableCell component="th" scope="row" onClick={() => handleKeyClick(transaction.key)}>
                  <Button
                    variant="contained"
                    size="small"
                    style={{ backgroundColor: '#02aaff', color: '#fff' }}
                    sx={{ marginRight: 1 }}
                    onClick={() => {
                      handleKeyClick(transaction.key);
                    }}
                  >
                    {transaction.key}
                  </Button>
                </TableCell>
                <TableCell align="right">{calculateBetAmount(transaction)}</TableCell>
                <TableCell align="right">{calculateDividend(transaction)}</TableCell>
                <TableCell align="right">
                  {transaction["3"] && transaction["3"].length
                    ? transaction["3"].map((command: any, index: any) => (
                      <React.Fragment key={index}>
                        {command}
                        <br />
                      </React.Fragment>
                    ))
                    : null}
                  <p className="time">{showTransaction(transaction)}</p>
                </TableCell>
                <TableCell align="right">
                  {transaction["9999"] && transaction["9999"].length
                    ? transaction["9999"].map((command: any, index: any) => (
                      <React.Fragment key={index}>
                        {command}
                        <br />
                      </React.Fragment>
                    ))
                    : null}
                  <p className="time">{showCustomTransaction(transaction)}</p>
                </TableCell>
                <TableCell align="right">{transaction.after_dividend_balance ? transaction.after_dividend_balance : (transaction.after_balance ? transaction.after_balance : "/")}</TableCell>
                {/* <TableCell align="right">
                  {transaction
                    ? transaction["landscape"]
                    : null}  
                  </TableCell> */}
                  <TableCell align="right">
                  {transaction
                    ? transaction["banker_category"]
                    : null}  
                  </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={4} align="center">Today's race not started yet.</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};  