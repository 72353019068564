import React from "react";  
import FormControl from "@mui/material/FormControl";  
import InputLabel from '@mui/material/InputLabel';  
import Select from '@mui/material/Select';  
import MenuItem from "@mui/material/MenuItem";  
  
interface RaceDateSelectProps {  
  selectedRaceDate: string;  
  raceDates: string[];  
  setRaceDate: (value: string) => void;  
}  
  
export const RaceDateSelect: React.FC<RaceDateSelectProps> = ({ selectedRaceDate, raceDates, setRaceDate }) => {  
  return (  
    <FormControl variant="outlined" sx={{ minWidth: 120, marginBottom: 2 }}>  
      <InputLabel id="race-date-label">Race Date</InputLabel>  
      <Select  
        labelId="race-date-label"  
        id="race-date-select"  
        value={selectedRaceDate}  
        onChange={(event) => { setRaceDate(event.target.value); localStorage.setItem('race_date', event.target.value); }}  
        label="Race Date"  
      >  
        {raceDates.map((date) => (  
          <MenuItem key={date} value={date}>{date}</MenuItem>  
        ))}  
      </Select>  
    </FormControl>  
  );  
};  
