import React from 'react';
import { Routes, Route } from 'react-router';
import { TriggerPage, UserPage, LoginPage, LogoutPage } from './page/page-routes'

export const Rroutes = () => {
  return (
    // <Suspense>
    <Routes>
      {/* <Route path='*' element={<NotFoundPage />} /> */}
      <Route path='*' element={<UserPage />} />
      <Route path='/' element={<LoginPage />} />
      <Route path='/user' element={<UserPage />} />
      <Route path='/login' element={<LoginPage />} />
      <Route path='/logout' element={<LogoutPage />} />
      <Route path='/trigger' element={<TriggerPage />} />
    </Routes>
  );
};

export default Rroutes;
