import React from 'react';  
import Streaming from '../../component/Streaming/Streaming';  
import Fetching from '../../component/Fetching/Fetching';  
import './TriggerPage.css';  

const TriggerPage: React.FC = () => {  
  return (  
    <div className="trigger-page-container">  
      <Streaming />  
      <Fetching />  
    </div>  
  );  
};  

export default TriggerPage;  
