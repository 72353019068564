import { useState, useEffect } from "react";
// import { Header } from "../../component/Header";
// import { Footer } from "../../component/Footer";
import "../../App.css";
import "./component/style/LoginPage.css"
import { LoadingProvider } from '../../component/Loading/Provider';
import { LoginPageContent } from "./component/LoginPageContent/LoginPageContent";

function LoginPage() {
  const [loginToken, setloginToken] = useState(localStorage.getItem("loginToken") || '');
  // if (loginToken || loginToken !== "") {
  //   window.location.href = '/profile';
  // }
  useEffect(() => {  
    if (localStorage.getItem("username")) {  
      window.location.href = '/user';  
    }  
  }, []);  

  return (
    <div className='Page-Container'>
      <LoadingProvider>
        {/* <Header /> */}
        <div className='Page-Content'>
          <LoginPageContent />
        </div>
        {/* <Footer /> */}
      </LoadingProvider>
    </div>
  );
}

export default LoginPage;
