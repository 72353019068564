// IMPORTS\
import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import Button from "@mui/material/Button";
import Snackbar from '@mui/material/Snackbar';

// STYLES
const styles = {
  details: {
    padding: "1rem",
    borderTop: "1px solid #e1e1e1"
  },
  value: {
    padding: "1rem 2rem",
    borderTop: "1px solid #e1e1e1",
    color: "#899499"
  }
};

//APP
export default function ProfileCard(props: any) {
  const [bettingStrategy, setBettingStrategy] = useState(localStorage.getItem('bettingStrategy') || '');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const handleStrategyClick = async () => {
    const localBettingStrategy = localStorage.getItem('bettingStrategy');
    const username = localStorage.getItem('username');
    const response = await fetch('https://api-ihr.agileexlab.net/ihr-luck/v4/api/update-betting-choice', {
      // const response = await fetch('http://localhost:8000/api/update-betting-choice', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username: username,
        betting_choice: localBettingStrategy,
      }),
    })
      .then(response => response.json())
      .then(data => {
        // console.log(data)
      })
      .catch(error => console.error('Error:', error));
    setSnackbarOpen(true);
    setTimeout(() => setSnackbarOpen(false), 3000);
  };
  useEffect(() => {
    if (props.bettingChoice === "Hold") {
      setBettingStrategy("Hold-to-bet");
    } else if (props.bettingChoice === "No") {
      setBettingStrategy("Not-to-bet");
    } else if (props.bettingChoice === "Auto") {
      setBettingStrategy("Auto-bet");
    }
  }, [props.bettingChoice]);
  return (
    <Card variant="outlined">
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        {/* CARD HEADER START */}
        <Grid item sx={{ p: "1.5rem 0rem", textAlign: "center" }}>
          {/* DESCRIPTION */}
          <Typography variant="h6">{props.name}</Typography>
          <Typography color="text.secondary">{props.sub}</Typography>
        </Grid>

        {/* CARD HEADER END */}
        <Grid>
          <Button
            variant="contained"
            size="small"
            style={bettingStrategy === 'Auto-bet' ? { backgroundColor: '#4caf50', color: '#fff' } : { backgroundColor: '#e0e0e0' }}
            sx={{ marginRight: 1 }}
            onClick={() => {
              localStorage.setItem('bettingStrategy', 'Auto-bet');
              setBettingStrategy('Auto-bet');
              handleStrategyClick();
            }}
          >
            Auto-bet
          </Button>

          <Button
            variant="contained"
            size="small"
            style={bettingStrategy === 'Hold-to-bet' ? { backgroundColor: '#ff9800', color: '#fff' } : { backgroundColor: '#e0e0e0' }}
            sx={{ marginRight: 1 }}
            onClick={() => {
              localStorage.setItem('bettingStrategy', 'Hold-to-bet');
              setBettingStrategy('Hold-to-bet');
              handleStrategyClick();
            }}
          >
            Hold-to-bet
          </Button>

          <Button
            variant="contained"
            size="small"
            style={bettingStrategy === 'Not-to-bet' ? { backgroundColor: '#f44336', color: '#fff' } : { backgroundColor: '#e0e0e0' }}
            onClick={() => {
              localStorage.setItem('bettingStrategy', 'Not-to-bet');
              setBettingStrategy('Not-to-bet');
              handleStrategyClick();
            }}
          >
            Not-to-bet
          </Button>
        </Grid>
        {/* DETAILS */}
        <Grid container>
          <Grid item xs={6}>
            <Typography style={styles.details}>Account Balance</Typography>
            {/* <Typography style={styles.details}>Betting Unit</Typography> */}
            <Typography style={styles.details}>Betting Unit</Typography>
            <Typography style={styles.details}>Current Race</Typography>
          </Grid>
          {/* VALUES */}
          <Grid item xs={6} sx={{ textAlign: "end" }}>
            <Typography style={styles.value}>{props.accountBalance}</Typography>
            {/* <Typography style={styles.value}>{props.unit}</Typography> */}
            <Typography style={styles.value}>{props.unit}U</Typography>
            <Typography style={styles.value}>{props.nextRace} - #{props.currentRaceNum}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Snackbar
        open={snackbarOpen}
        message="Changed Betting Strategy"
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
      />
    </Card>
  );
}
