import React, { useState } from 'react'
import { LoadingContext } from '../Context'

const LoadingProvider = ( { children }: any ) => {
  /**
   * Loading state/controls
   */
  const loadingFinish = () => {
    toggleLoading(prevState => {
      return {
        ...prevState,
        loadingBool: false
      }
    })
  }

  const loadingState = {
    loadingBool: true,
    loadingFinish
  }

  const [loading, toggleLoading] = useState(loadingState);
  return (
    <LoadingContext.Provider value={loading}>
      { children }
    </LoadingContext.Provider>
  )
}

export default LoadingProvider