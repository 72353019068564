import { useEffect } from "react";

function LogoutPage() {
  useEffect(() => {  
    localStorage.removeItem("username");  
    localStorage.removeItem("loginToken");  
    localStorage.removeItem("publicKey");  
    window.location.href = '/login';  
  }, []);  
  return (
    <div className='Page-Container'>
      <div className='Page-Content'>
        <div className="Page-Content-Title">
          <span>IHR Luck System</span>
        </div>
      </div>
    </div>
  );
}

export default LogoutPage;
